import React from 'react';
import { graphql } from 'gatsby';
import {
  shape, arrayOf, any, string
} from 'prop-types';
import styled from 'styled-components';

import HeaderLogo from '../components/HeaderLogo';
import HeaderTitle from '../components/HeaderTitle';
import Footer from '../components/Footer';
import '../css/followUpTemplate.css';

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
`;

const Inner = styled.div`
  max-width: 128rem;
  padding: 6rem 6.7rem;
  margin: 0 auto;
  width: 100%;
  ul {
    padding-left: 25px;
  }
  li,
  a {
    font-size: 1.6rem;
  }
`;

const HomePage = ({ data }) => (
  <>
    <HeaderLogo />
    <HeaderTitle title='Follow up pages' />
    <Wrapper>
      <Inner>
        <h2>Follow up links:</h2>
        <div>
          {data.allContentfulEvent.edges.map(page => (
            <>
              <h3>
                <strong>{page.node.title}</strong>
              </h3>
              <ul>
                {data.allSitePage.nodes
                  .filter(
                    item =>
                      item.context
                      && item.context.permalink === page.node.permalink
                  )
                  .map(each => (
                    <li>
                      <a href={each.path}>{each.path}</a>
                    </li>
                  ))}
              </ul>
            </>
          ))}
          <>
            <h3>
              <strong>WPW</strong>
            </h3>
            <ul>
              <li>
                <a href='/wpw-member'>/wpw-member</a>
              </li>
              <li>
                <a href='/wpw-non-member'>/wpw-non-member</a>
              </li>
            </ul>
          </>
        </div>
      </Inner>
    </Wrapper>
    <Footer />
  </>
);

HomePage.propTypes = {
  data: shape({
    allSitePage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            context: shape({
              type: any,
            }).isRequired,
            id: string.isRequired,
            path: string.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default HomePage;

export const pageQuery = graphql`
  query {
    allContentfulEvent {
      edges {
        node {
          id
          title
          permalink
        }
      }
    }
    allSitePage {
      nodes {
        context {
          isMember
          isCPO
          permalink
        }
        path
      }
    }
  }
`;
